
import IndustriaService from "../../../Services/IndustriaService";
export default {
  name: "industria-gestor",
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Configuración" }, { etiqueta: "Industrias" }],
      statusOpciones: [
        { value: 200, label: "Activo" },
        { value: 300, label: "Eliminado" },
      ],
      filtros: {
        busqueda: "",
        status: [],
      },
      industrias: [],
      columnas: [
        {
          name: "nombre",
          header: "Industria",
          align: "left",
          field: "nombre",
          minWidth: 120,
          width: 140,
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 120,
          width: 140,
          templateName: "status",
          statusName: "status_nombre",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 120,
          width: 155,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 130,
          width: 160,
        },
        {
          name: "opciones",
          header: "",
          align: "center",
          minWidth: 50,
          width: 50,
          columnChooser: false,
          templateName: "opciones",
          templateRestriccionCampo: "status",
          templateRestriccionValores: [300],
          templateOpciones: [
            { accion: "editar", icono: "editar" },
            { accion: "eliminar", icono: "eliminar" },
          ],
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      agregarIndustriaObj: {
        nombre: "",
      },
      modalEditar: false,
      modalEliminar: false,
      editarIndustriaObj: {},
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerIndustrias(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        status: this.obtenerArrayParametrosQuery(query.s, true), // El segundo parametro es para arrays con valores numericos
        ordenar: "titulo_asc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.status,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerIndustrias(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await IndustriaService.listarGestor(this.filtros)
        .then((resp) => {
          let industrias = resp;
          this.industrias = industrias.map((p) => {
            return {
              ...p,
              registro_fecha: new Date(p.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarIndustria(loader = true) {
      if (loader) this.loader(true);
      await IndustriaService.agregar(this.agregarIndustriaObj)
        .then(async (resp) => {
          this.cerrarModalAgregar();
          await this.obtenerIndustrias(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarIndustria(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        industriaId: this.editarIndustriaObj.industria_id,
        nombre: this.editarIndustriaObj.nombre,
      };
      await IndustriaService.editar(datos)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerIndustrias(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async eliminarIndustria(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        industriaId: this.editarIndustriaObj.industria_id,
      };
      await IndustriaService.eliminar(datos)
        .then(async (resp) => {
          this.cerrarModalEliminar();
          await this.obtenerIndustrias(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [],
        ordenar: "titulo_asc",
      };
      this.obtenerIndustrias();
      this.accionLimpiar = true;
    },
    manejarClickOpcion(industria, accion) {
      if (accion == "editar") this.abrirModalEditar(industria);
      if (accion == "eliminar") this.abrirModalEliminar(industria);
    },
    abrirModalAgregar() {
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.agregarIndustriaObj = {
        nombre: "",
      };
      this.modalAgregar = false;
    },
    abrirModalEditar(industria) {
      this.editarIndustriaObj = industria;
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarIndustriaObj = {};
      this.modalEditar = false;
    },
    abrirModalEliminar(industria) {
      this.editarIndustriaObj = industria;
      this.modalEliminar = true;
    },
    cerrarModalEliminar() {
      this.editarIndustriaObj = {};
      this.modalEliminar = false;
    },
  },
};
